/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BimFaceModelNoToken } from './BimFaceModelNoToken';
import type { VOSysUserSerializerWithNoTokenNoMenus } from './VOSysUserSerializerWithNoTokenNoMenus';

/**
 * Project(project_id, project_name, project_description, huasi_id, huasi_domain, is_open_storage_task, storage_time_cycle, nav_url, project_img, longitude, latitude, view_home, view_geology, altitude, work_steps, project_address, project_price, project_company, project_area, project_cycle, project_duty_com, project_design_com, project_check_com, project_build_com, project_status, creator_id, create_time, update_id, update_time, is_deleted)
 */
export type VOProject = {
    readonly project_id?: number;
    project_name: string;
    project_description?: string | null;
    /**
     * 默认为空
     */
    nav_url?: string | null;
    /**
     * 1为正常2为异常
     */
    project_status?: VOProject.project_status;
    /**
     * 以;作为分割
     */
    project_img?: string | null;
    project_address?: string | null;
    /**
     * varchat类型
     */
    project_price?: string | null;
    /**
     * varchat类型
     */
    project_company?: string | null;
    /**
     * varchat类型
     */
    project_area?: string | null;
    /**
     * varchat类型
     */
    project_cycle?: string | null;
    project_duty_com?: string | null;
    project_design_com?: string | null;
    project_check_com?: string | null;
    project_build_com?: string | null;
    /**
     * 以json数据保存
     */
    view_home?: any;
    /**
     * 以json数据保存
     */
    view_geology?: any;
    altitude?: number | null;
    work_steps?: any;
    readonly create_time?: string;
    readonly update_time?: string;
    is_deleted?: boolean;
    readonly bimfacemodel_set?: Array<BimFaceModelNoToken>;
    readonly users?: Array<VOSysUserSerializerWithNoTokenNoMenus>;
};

export namespace VOProject {

    /**
     * 1为正常2为异常
     */
    export enum project_status {
        '_1' = 1,
        '_2' = 2,
    }


}
