/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * DataWarningRecord(threshold_id, project_id, mpdata_id, dwrecord_hanlder, dwrecord_id, dwrecord_name, dwrecord_remark, dwrecord_type, dwrecord_hasreported, dwrecord_status, dwrecord_countermeasure, dwrecord_attachment, creator_id, create_time, update_id, update_time, is_deleted)
 */
export type DataWarningRecord = {
    readonly dwrecord_id?: number;
    mpdata_id?: number | null;
    dwrecord_name?: string | null;
    dwrecord_remark?: string | null;
    dwrecord_type?: string | null;
    dwrecord_hasreported?: boolean;
    dwrecord_status?: DataWarningRecord.dwrecord_status;
    readonly handler_user?: string;
    create_time?: string;
    /**
     * 每个附件以;结尾
     */
    dwrecord_attachment?: string | null;
    readonly threshold?: string;
    readonly measuring_point_data?: string;
    dwrecord_countermeasure?: string | null;
};

export namespace DataWarningRecord {

    export enum dwrecord_status {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
    }


}
