/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * 因为存在相互导入的问题，计算任务模块暂时放入system_models
 */
export type VOComputingTask = {
    readonly compute_task_id?: number;
    compute_task_name?: string | null;
    compute_status?: VOComputingTask.compute_status;
    /**
     * 以json数据保存
     */
    input_parameters?: any;
    /**
     * 以json数据保存
     */
    result?: any;
    third_party_id?: string | null;
    readonly create_time?: string;
    readonly creator?: string;
    readonly algorithm?: string;
    readonly project?: string;
};

export namespace VOComputingTask {

    export enum compute_status {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
    }


}
