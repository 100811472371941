const FoundationManage = () => import("@/views/foundationManage/index.vue");
export default {
  path: "/foundationManage/:index",
  name: "foundationManage",
  meta: {
    title: "项目详情",
    showLink: false
  },
  component: FoundationManage
} as RouteConfigsTable;
