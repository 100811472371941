/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * MeasuringPoint(project_id, mitem_id, epoint_id, mpoint_id, mpoint_name, huasi_id, node_num, node_related_attribution, longitude, latitude, status, remark, creator_id, create_time, update_id, update_time, is_deleted)
 */
export type MeasuringPoint = {
    readonly mpoint_id?: number;
    mpoint_name?: string | null;
    huasi_id: number;
    readonly project?: string;
    readonly measuring_item?: string;
    /**
     * 1为正常，2为异常，3为封存
     */
    status?: MeasuringPoint.status;
    epoint_id?: number | null;
    readonly element_point?: string;
};

export namespace MeasuringPoint {

    /**
     * 1为正常，2为异常，3为封存
     */
    export enum status {
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
    }


}
