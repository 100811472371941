/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * 带目录结构的menu（已废弃，只在获取所有菜单的时候使用）
 */
export type BaseMenu = {
    readonly menu_id?: number;
    menu_name: string;
    menu_icon?: string | null;
    menu_url?: string | null;
    menu_sort: number;
    /**
     * 默认为1 1为菜单 2为按钮
     */
    menu_type?: BaseMenu.menu_type;
    /**
     * 默认为1 1为正常 0停用
     */
    status?: BaseMenu.status;
    readonly children?: string;
};

export namespace BaseMenu {

    /**
     * 默认为1 1为菜单 2为按钮
     */
    export enum menu_type {
        '_1' = 1,
        '_2' = 2,
    }

    /**
     * 默认为1 1为正常 0停用
     */
    export enum status {
        '_1' = 1,
        '_2' = 2,
    }


}
