/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TokenRefresh } from "../models/TokenRefresh";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class TokenService {
  /**
   * @param data
   * @returns TokenRefresh
   * @throws ApiError
   */
  public static tokenRefreshCreate(
    data: TokenRefresh
  ): CancelablePromise<TokenRefresh> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/token/refresh",
      body: data
    });
  }
}
