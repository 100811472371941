/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * 因为存在相互导入的问题，计算任务模块暂时放入system_models
 */
export type OriginalPitAlgHeatMapPlane = {
    project_id?: number | null;
    readonly plane_id?: number;
    plane_name?: string | null;
    height?: number | null;
    /**
     * 以json数据保存，内部存储多个点的xyz坐标
     */
    boundary?: any;
    color_radius?: number;
    /**
     * 地平线z轴高度
     */
    horizon?: number;
    /**
     * 为生成的平面点提供噪声
     */
    noise?: number;
    /**
     * 比例放大最终结果
     */
    rate?: number;
    /**
     * 现实物体与模型的比例关系，该值无需改变
     */
    scale?: number;
    /**
     * value偏移
     */
    bias?: number;
    /**
     * 归一化类型value的取值为(0, 1)
     */
    point_value_type?: OriginalPitAlgHeatMapPlane.point_value_type;
    /**
     * 最终生成h_num*v_num个点
     */
    vertical_num?: number;
    /**
     * 最终生成h_num*v_num个点
     */
    horizontal_num?: number;
    /**
     * 以json数据保存，内部存储该点的xyz坐标和value
     */
    points?: any;
    /**
     * 以json数据保存，内部存储该点的xyz坐标和value
     */
    original_points?: any;
};

export namespace OriginalPitAlgHeatMapPlane {

    /**
     * 归一化类型value的取值为(0, 1)
     */
    export enum point_value_type {
        '_1' = 1,
        '_2' = 2,
    }


}
