/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * 与model字段一致，用于添加用户时使用
 */
export type OriginSysUser = {
    readonly user_id?: number;
    role_id?: number | null;
    companies?: Array<number>;
    projects?: Array<number>;
    user_account: string;
    /**
     * 使用admin和接口修改该字段会自动加密，数据库请不要直接修改该字段
     */
    user_password?: string;
    user_name: string;
    user_avatar?: string | null;
    user_email?: string | null;
    user_phone?: string | null;
    readonly user_create_time?: string;
    /**
     * 默认1为正常，0表示停用
     */
    user_status?: OriginSysUser.user_status | null;
};

export namespace OriginSysUser {

    /**
     * 默认1为正常，0表示停用
     */
    export enum user_status {
        '_1' = 1,
        '_0' = 0,
    }


}
