/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OriginSysUser } from "../models/OriginSysUser";
import type { VOSysUserSerializerWithNoToken } from "../models/VOSysUserSerializerWithNoToken";
import type { VOSysUserSerializerWithNoTokenNoMenus } from "../models/VOSysUserSerializerWithNoTokenNoMenus";
import type { VOSysUserSerializerWithToken } from "../models/VOSysUserSerializerWithToken";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class SysuserService {
  /**
   * 批量分页查询用户
   * 如果想要进行字段搜索，可以使用如 /sysuser?user_name=zeeland的方式进行查询，暂时不支持模糊搜索
   * :param request: /sysuser
   * @param userId
   * @param userName
   * @param userAccount
   * @param userEmail
   * @param userPhone
   * @param userCreateTime
   * @param page A page number within the paginated result set.
   * @param size Number of results to return per page.
   * @returns VOSysUserSerializerWithNoTokenNoMenus 用户VOSysUserSerializer，无菜单，用于批量查询用户信息
   * @throws ApiError
   */
  public static sysuserList(
    userId?: number,
    userName?: string,
    userAccount?: string,
    userEmail?: string,
    userPhone?: string,
    userCreateTime?: string,
    page?: number,
    size?: number
  ): CancelablePromise<Array<VOSysUserSerializerWithNoTokenNoMenus>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/sysuser/",
      query: {
        user_id: userId,
        user_name: userName,
        user_account: userAccount,
        user_email: userEmail,
        user_phone: userPhone,
        user_create_time: userCreateTime,
        page: page,
        size: size
      }
    });
  }

  /**
   * 创建用户
   * 创建新的用户
   * @param data
   * @returns VOSysUserSerializerWithToken
   * @throws ApiError
   */
  public static sysuserCreate(
    data: OriginSysUser
  ): CancelablePromise<VOSysUserSerializerWithToken> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/sysuser/",
      body: data
    });
  }

  /**
   * 用户登陆
   * 用户登陆，如果该用户不存在或者密码错误则返回502, 否则返回用户信息
   * @param data
   * @returns VOSysUserSerializerWithToken
   * @throws ApiError
   */
  public static sysuserLogin(data: {
    user_account: string;
    user_password: string;
  }): CancelablePromise<VOSysUserSerializerWithToken> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/sysuser/login/",
      body: data
    });
  }

  /**
   * 获取用户信息
   * 如果在headers中传入token则为查询单个用户的信息
   * {"Authorization": "user token"}
   * @param authorization token
   * @param userId
   * @param userName
   * @param userAccount
   * @param userEmail
   * @param userPhone
   * @param userCreateTime
   * @param page A page number within the paginated result set.
   * @param size Number of results to return per page.
   * @returns VOSysUserSerializerWithToken
   * @throws ApiError
   */
  public static sysuserTokenRead(): CancelablePromise<VOSysUserSerializerWithToken> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/sysuser/token/",
      query: {}
    });
  }

  /**
   * @param userId A unique integer value identifying this 用户管理.
   * @returns VOSysUserSerializerWithNoToken
   * @throws ApiError
   */
  public static sysuserRead(
    userId: number
  ): CancelablePromise<VOSysUserSerializerWithNoToken> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/sysuser/{user_id}/",
      path: {
        user_id: userId
      }
    });
  }

  /**
   * @param userId A unique integer value identifying this 用户管理.
   * @param data
   * @returns VOSysUserSerializerWithNoToken
   * @throws ApiError
   */
  public static sysuserUpdate(
    userId: number,
    data: VOSysUserSerializerWithNoToken
  ): CancelablePromise<VOSysUserSerializerWithNoToken> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/sysuser/{user_id}/",
      path: {
        user_id: userId
      },
      body: data
    });
  }

  /**
   * 更新用户信息，别用put接口，用这个
   * @param userId A unique integer value identifying this 用户管理.
   * @param data
   * @returns VOSysUserSerializerWithNoToken 用户接口使用的serializer
   * @throws ApiError
   */
  public static sysuserPartialUpdate(
    userId: number,
    data: VOSysUserSerializerWithNoToken
  ): CancelablePromise<VOSysUserSerializerWithNoToken> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/sysuser/{user_id}/",
      path: {
        user_id: userId
      },
      body: data
    });
  }

  /**
   * @param userId A unique integer value identifying this 用户管理.
   * @returns void
   * @throws ApiError
   */
  public static sysuserDelete(userId: number): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/sysuser/{user_id}/",
      path: {
        user_id: userId
      }
    });
  }
}
