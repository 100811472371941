import { defineStore } from "pinia";
import { store } from "@/store";
import { userType } from "./types";
import { routerArrays } from "@/layout/types";
import { resetRouter, router } from "@/router";
import { storageSession } from "@pureadmin/utils";
import { RefreshTokenResult, TokenResult } from "@/api/user";
import { useMultiTagsStoreHook } from "@/store/modules/multiTags";
import { type DataInfo, removeToken, sessionKey, setToken } from "@/utils/auth";
import { SysuserService, TokenService } from "@/client";
import { ElMessage, ElMessageBox, ElNotification } from "element-plus";
import NProgress from "@/utils/progress";

export const useUserStore = defineStore({
  id: "pure-user",
  state: (): userType => ({
    // 用户名
    user_account:
      storageSession().getItem<DataInfo<any[]>>(sessionKey)?.user_account ?? "",
    // 页面级别权限
    roles: storageSession().getItem<DataInfo<any[]>>(sessionKey)?.roles ?? []
  }),
  actions: {
    /** 存储用户名 */
    SET_USER_ACCOUNT(user_account: string) {
      this.user_account = user_account;
    },
    /** 存储角色 */
    SET_ROLES(roles: any[]) {
      this.roles = roles;
    },
    /** 登入 */
    async loginByUserAccount(data) {
      return new Promise<TokenResult>((resolve, reject) => {
        SysuserService.sysuserLogin(data)
          .then(data => {
            // 后端swagger给出的VOSysUserSerializerWithToken层级是错的，所以这里多一层data
            if (data.code === 200 && data.data) {
              const token_result = data.data;
              const role = token_result.role;
              // 后端给到role只能有单个，前端是支持多角色，所以转换一下，将来后端要改造
              token_result.roles = [role];
              delete token_result.role;
              setToken(token_result);
              // 解析跳转过来的url，如果有redirect_name则判断是否有对应的权限，有则跳转，没有则跳转到首页
              const url = window.location.href;

              // 解析url参数,获取redirect_name
              const params = new URLSearchParams(url.split("?")[1]);
              const redirect_name = params.get("redirect_name");
              // 把role.nav_url按照;分割成数组
              const nav_urls = role.nav_url.split(";");
              // 判断是否有对应的权限
              const hasPermission =
                nav_urls.includes(redirect_name) || role.nav_url === "*";
              // 如果有对应的权限则跳转，没有则跳转到首页
              if (hasPermission && redirect_name) {
                const redirect_url = `http://${redirect_name}${
                  import.meta.env.VITE_APP_DOMAIN
                }`;
                window.location.href = redirect_url;
                // 弹出一个通知，提示用户即将返回
                ElNotification({
                  title: "提示",
                  message: "登陆成功即将返回看板",
                  type: "success",
                  duration: 10000,
                  showClose: false
                });
              } else if (redirect_name) {
                ElMessage({
                  message: "无权限访问该页项目",
                  type: "error"
                });
              }
            }
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /** 前端登出（不调用接口） */
    logOut() {
      this.user_account = "";
      this.roles = [];
      removeToken();
      useMultiTagsStoreHook().handleTags("equal", [...routerArrays]);
      resetRouter();
      router.push("/login");
    },
    /** 刷新`token` */
    async handRefreshToken(data) {
      return new Promise<RefreshTokenResult>((resolve, reject) => {
        TokenService.tokenRefreshCreate(data)
          .then(resData => {
            if (resData.code === 200 && resData.data) {
              setToken(resData.data);
              resolve(resData);
            }
            if (resData.code !== 200) {
              NProgress.done();
              ElMessageBox.confirm(
                "登录状态已过期，您可以继续留在该页面，或者重新登录",
                "系统提示",
                {
                  confirmButtonText: "重新登录",
                  cancelButtonText: "取消",
                  type: "warning"
                }
              )
                .then(() => {
                  useUserStoreHook().logOut();
                })
                .catch(() => {});
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
});

export function useUserStoreHook() {
  return useUserStore(store);
}
