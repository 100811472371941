import Cookies from "js-cookie";
import { storageSession } from "@pureadmin/utils";
import { useUserStoreHook } from "@/store/modules/user";

// export interface DataInfo<T> {
//   /** token */
//   accessToken: string;
//   /** `accessToken`的过期时间（时间戳） */
//   expires: T;
//   /** 用于调用刷新accessToken的接口时所需的token */
//   refreshToken: string;
//   /** 用户名 */
//   username?: string;
//   /** 当前登陆用户的角色 */
//   roles?: Array<string>;
// }
export interface DataInfo<T> {
  /** token */
  token: string;
  refresh_token: string;
  token_expired_time: T;
  refresh_token_expired_time: T;
  user_account: string;
  /** 当前登陆用户的角色 */
  roles?: any[];
}

export const sessionKey = "user-info";
export const TokenKey = "authorized-token";

/** 获取`token` */
export function getToken(): DataInfo<string> {
  // 此处与`TokenKey`相同，此写法解决初始化时`Cookies`中不存在`TokenKey`报错
  // const token = Cookies.get(TokenKey);
  // console.log("token", token);
  // const jsonToken = token ? JSON.parse(token) : null;
  // console.log("jsonToken", jsonToken);
  // const sessionkey = storageSession().getItem(sessionKey);
  // console.log("sessionkey", sessionkey);
  return Cookies.get(TokenKey)
    ? JSON.parse(Cookies.get(TokenKey))
    : storageSession().getItem(sessionKey);
}

/**
 * @description 设置`token`以及一些必要信息并采用无感刷新`token`方案
 * 无感刷新：后端返回`accessToken`（访问接口使用的`token`）、`refreshToken`（用于调用刷新`accessToken`的接口时所需的`token`，`refreshToken`的过期时间（比如30天）应大于`accessToken`的过期时间（比如2小时））、`expires`（`accessToken`的过期时间）
 * 将`accessToken`、`expires`这两条信息放在key值为authorized-token的cookie里（过期自动销毁）
 * 将`username`、`roles`、`refreshToken`、`expires`这四条信息放在key值为`user-info`的sessionStorage里（浏览器关闭自动销毁）
 */
export function setToken(data: DataInfo<string>) {
  let expires = 0;
  const { token, refresh_token, token_expired_time } = data;
  expires = new Date(token_expired_time).getTime();
  // expires = new Date(data.expires).getTime(); // 如果后端直接设置时间戳，将此处代码改为expires = data.expires，然后把上面的DataInfo<Date>改成DataInfo<number>即可
  const cookieString = JSON.stringify({ token, expires, refresh_token });
  expires > 0
    ? Cookies.set(TokenKey, cookieString, {
        domain: import.meta.env.VITE_APP_DOMAIN,
        expires: (expires - Date.now()) / 86400000
      })
    : Cookies.set(TokenKey, cookieString);

  function setSessionKey(user_account: string, roles: any[]) {
    useUserStoreHook().SET_USER_ACCOUNT(user_account);
    useUserStoreHook().SET_ROLES(roles);
    storageSession().setItem(sessionKey, {
      user_account,
      refresh_token,
      expires,
      roles
    });
  }

  if (data.user_account && data.roles) {
    const { user_account, roles } = data;
    setSessionKey(user_account, roles);
  } else {
    const username =
      storageSession().getItem<DataInfo<any[]>>(sessionKey)?.user_account ?? "";
    const roles =
      storageSession().getItem<DataInfo<any[]>>(sessionKey)?.roles ?? [];
    setSessionKey(username, roles);
  }
}

/** 删除`token`以及key值为`user-info`的session信息 */
export function removeToken() {
  Cookies.remove(TokenKey, {
    domain: import.meta.env.VITE_APP_DOMAIN
  });
  sessionStorage.clear();
  // storageSession().clear();
}

/** 格式化token（jwt格式） */
export const formatToken = (token: string): string => {
  // return "Bearer " + token;
  return token;
};
