/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * SysRole(role_id, role_name, role_sort, role_status, role_remark, nav_url, system_title, system_icon, is_hidden_tab, creator_id, create_time, update_id, update_time, is_deleted)
 */
export type VOSysRole = {
    readonly role_id?: number;
    role_name: string;
    /**
     * 默认为空
     */
    nav_url?: string | null;
    system_title?: string | null;
    system_icon?: string | null;
    /**
     * True为隐藏，False为不隐藏，默认为False
     */
    is_hidden_tab?: boolean;
    role_sort?: number | null;
    /**
     * 默认1正常 0停用
     */
    role_status?: VOSysRole.role_status;
    role_remark?: string | null;
    readonly menus?: string;
    is_deleted?: boolean;
};

export namespace VOSysRole {

    /**
     * 默认1正常 0停用
     */
    export enum role_status {
        '_1' = 1,
        '_0' = 0,
    }


}
