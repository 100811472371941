/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * AlgorithmAPI(algorithm_id, api_id, api_name, request_method, api_url, api_type, remark)
 */
export type OriginalAlgorithmAPI = {
    readonly api_id?: number;
    api_name: string;
    request_method?: OriginalAlgorithmAPI.request_method;
    api_url: string;
    api_type?: OriginalAlgorithmAPI.api_type;
    /**
     * 包括算法如何传参，算法的请求方式等
     */
    remark?: string | null;
    algorithm_id?: number | null;
};

export namespace OriginalAlgorithmAPI {

    export enum request_method {
        GET = 'GET',
        POST = 'POST',
    }

    export enum api_type {
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
    }


}
