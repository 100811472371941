/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BaseCompany } from './BaseCompany';

/**
 * 用户VOSysUserSerializer，无菜单，用于批量查询用户信息
 */
export type VOSysUserSerializerWithNoTokenNoMenus = {
    readonly user_id?: number;
    user_account: string;
    user_name: string;
    user_email?: string | null;
    user_phone?: string | null;
    user_avatar?: string | null;
    /**
     * 默认1为正常，0表示停用
     */
    user_status?: VOSysUserSerializerWithNoTokenNoMenus.user_status | null;
    readonly user_create_time?: string;
    readonly role?: any;
    companies: Array<BaseCompany>;
};

export namespace VOSysUserSerializerWithNoTokenNoMenus {

    /**
     * 默认1为正常，0表示停用
     */
    export enum user_status {
        '_1' = 1,
        '_0' = 0,
    }


}
