/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * DataWarningThreshold(project_id, mitem_id, threshold_id, threshold_name, threshold_value, threshold_field_name, warning_type, warning_rank, creator_id, create_time, update_id, update_time, is_deleted)
 */
export type DataWarningThreshold = {
    readonly threshold_id?: number;
    readonly project?: string;
    readonly measuring_item?: string;
    threshold_name: string;
    threshold_value: number;
    warning_type?: DataWarningThreshold.warning_type;
    warning_rank?: DataWarningThreshold.warning_rank;
};

export namespace DataWarningThreshold {

    export enum warning_type {
        '_1' = 1,
        '_2' = 2,
    }

    export enum warning_rank {
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
    }


}
