import { SysuserService } from "@/client";

// type Result = {
//   success: boolean;
//   data: Array<any>;
// };

// export const getAsyncRoutes = () => {
//   return http.request<Result>("get", "/getAsyncRoutes");
// };
export const getAsyncRoutes = () => {
  return new Promise<any>((resolve, reject) => {
    SysuserService.sysuserTokenRead()
      .then(data => {
        if (data) {
          const token_result = data.data;
          const role = token_result.role;
          const menus = role.menus;
          resolve(menus);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};
